<template>
<b-overlay rounded="sm" :show="loading">
  <AuthWrapper>
    <template #card>
      <b-card-text class="text-right mt-0 text-danger cursor-pointer" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Logout</span>
      </b-card-text>
      <div class="text-center">
        <div>
          <h1>Residential Info</h1>
        </div>
        <div class="my-2">
          <p>We will like to know where you live</p>
        </div>
      </div>
      <b-alert
        variant="danger"
        show
      >
        <div v-if="errorMessage" class="alert-body font-small-2">
          <b-card-text>
            <feather-icon icon="AlertCircleIcon" />
            <small>{{ errorMessage }}</small>
          </b-card-text>
        </div>
      </b-alert>
      <div>
        <validation-observer ref="residentialForm">
          <b-form @submit.prevent="saveResidentialInformation">
            <b-form-group label="Residential Address" label-for="address">
              <validation-provider #default="{ errors }" name="address" rules="required">
                <div class="exxtra-input">
                        <span class="leading">
                          <ResidentialIconOne />
                        </span>
                  <input id="forgot-password-field" v-model="address" placeholder="Enter your residential Address">
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Nearest Landmark" label-for="nearest_landmark">
              <validation-provider #default="{ errors }" name="nearest_landmark" rules="required">
                <div class="exxtra-input">
                        <span class="leading">
                          <NearestLandmark />
                        </span>
                  <input id="nearest_landmark" v-model="nearest_landmark" placeholder="Enter your nearest landmark ">
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Marital Status" label-for="marital_status" class="mt-2">
              <validation-provider #default="{ errors }" name="marital_status" rules="required">
                <div class="exxtra-input">
                  <span class="leading">
                    <Person />
                  </span>
                  <b-form-select
                    id="marital_status"
                    v-model="marital_status"
                    :options="maritalStatusOptions"
                    style="height: 3.3rem"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="d-flex my-1 mt-2 justify-content-around align-items-center">
              <div>
                <b-button type="submit" variant="outline-primary" block class="py-1"
                          :to="{ name: 'onboarding-stage-1' }"
                >
                  <feather-icon icon="ChevronLeftIcon" />
                  Previous
                </b-button>
              </div>
              <div>
                <b-button type="submit" variant="primary" block class="py-1">
                  Complete
                  <feather-icon icon="ChevronRightIcon" />
                </b-button>
              </div>
            </div>

          </b-form>
        </validation-observer>

        <div class="text-center mt-1">
          <Loader />
        </div>
      </div>
    </template>
  </AuthWrapper>
</b-overlay>
</template>

<script>
import store from '@/store/index'
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import {
  email,
  required
} from '@validations';
import Person from "@/assets/svg/Person.svg";
import Loader from "@/assets/svg/Loader.svg";
import TurtleRentLogo from '@core/layouts/components/Logo.vue'
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import NearestLandmark from "@/assets/svg/NearestLandmark.svg";
import Rectangles from "@/pages/auth/shared-components/rectangles.vue";
import ResidentialIconOne from "@/assets/svg/ResidentialIcon.svg";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BAlert,
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BOverlay,
  BRow,
  BFormSelect
} from 'bootstrap-vue'
import {
  get
} from "lodash";
/* eslint-disable global-require */
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'

export default {
  components: {
    AuthWrapper,
    Rectangles,
    NearestLandmark,
    Loader,
    ResidentialIconOne,
    Person,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BFormSelect,
    BottomDesign,
    TurtleRentLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      hasErrorMessage: false,
      errorMessage: "",
      address: '',
      nearest_landmark: '',
      marital_status: '',
      // validation
      required,
      email,
      maritalStatusOptions: [
        {
          value: "single",
          text: "Single"
        },
        {
          value: "married",
          text: "Married"
        },
        {
          value: "divorced",
          text: "Divorced"
        }
      ]
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    const {
      error
    } = this.$route.query;
    if (error) {
      this.hasErrorMessage = true;
      this.errorMessage = 'The password reset token has expired. Please enter your email / phone to request for new token.'
      this.$router.replace({
        query: {}
      });
    }
  },
  mounted() {
    this.address = this.getValueFromSource(this.currentUser, 'residential_address');
    this.marital_status = this.getValueFromSource(this.currentUser, 'marital_status');
    this.nearest_landmark = this.getValueFromSource(this.currentUser, 'nearest_landmark');
  },
  methods: {
    async saveResidentialInformation() {
      try {
        this.loading = true;
        const success = await this.$refs.residentialForm.validate();
        if (!success) {
          return;
        }

        const {
          address: residential_address,
          nearest_landmark,
          marital_status
        } = this;
        const formData = new FormData()
        formData.append("residential_address", residential_address);
        formData.append("nearest_landmark", nearest_landmark);
        formData.append("marital_status", marital_status);

        await this.useJwt().authService.saveResidentialData(formData);

        this.$router.replace({
            name: "client-home"
          })
          .catch(() => {});
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.errorMessage = error_message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
